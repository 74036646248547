import React, { useRef, useState } from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { setField } from '../../actions/forms'
import { requestRemove, requestRemoveStartsWith } from '../../actions'
import { isPending, getField } from "../../reducers"
import {
    TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_DYNAMIC_SEARCH_SPECIES_REQUEST_ID,
    TAXONDB_DYNAMIC_SEARCH_REQUEST_ID,
    AGGREGATION_REPORT_REQUEST_ID_PREFIX,
} from '../../settings'
import {
    MULTIMEDIA_FIELD_NAME,
    BOTANIKAZOOLOGIA_FIELD_NAME,
    ROK_ZBIORU_FIELD_NAME,
    DATAZBIORU_FIELD_NAME,
    RODZAJ_GATUNEK_ATTRIBUTE_NAME,
    LOCATION_ATTRIBUTE_NAME,
    COORDINATES_PRECISION,
} from './attributesStructure'
import { Container, Dropdown, NavDropdown, Button, Overlay, Popover } from "react-bootstrap"
import { setElementStructure, deleteElementStructure, getElementStructure } from '../../utils/search'
import DynamicSearchFormElement from './DynamicSearchFormElement'
import InnerPanel from './DynamicSearchFormPanel'
import Confirm from '../Confirm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

const DynamicSearchFormPanel = props => {
    const { t } = useTranslation([ 'common', 'taxondb', 'taxondb-attributes', 'forms', 'taxondb-groups' ])

    const [showPopover, setShowPopover] = useState(false)
    const target = useRef()
    const ref = useRef()

    const addDynamicField = (id) => {
        let temp = { ...props.fields }
        const next = Object.keys(props.thisField).reduce((acc, obj) => {
            if (obj.startsWith("or_")) {
                const n = parseInt(obj.substring(3))
                return Math.max(acc, n + 1)
            } else if (obj.startsWith("not_")) {
                const n = parseInt(obj.substring(4))
                return Math.max(acc, n + 1)
            }
            return acc
        }, 0)
        if (props.elementId.startsWith("or_")) {
            const indx = props.thisField.length
            const obj = {}
            if (id === "or") {
                obj[ "or_" + next ] = [ {}, {} ]
            } else if (id === "not") {
                obj[ "not_" + next ] = {}
            } else {
                obj[ id ] = ""
            }
            temp = setElementStructure([ ...props.path, indx ], temp, obj)
        } else {
            if (id === "or") {
                temp = setElementStructure([ ...props.path, "or_" + next ], temp, [ {}, {} ])
            } else if (id === "not") {
                temp = setElementStructure([ ...props.path, "not_" + next ], temp, {})
            } else {
                temp = setElementStructure([ ...props.path, id ], temp, "")
            }
        }
        props.setFields(temp)
        props.clearResponse()
    }

    const removePanel = () => {
        const temp = deleteElementStructure(props.path, props.fields)
        props.setFields(temp)
        props.clearResponse()
    }

    const SingleMenuItem = props =>
        <>
            {!props.label &&
                <Dropdown.Item onClick={e => {addDynamicField(props.id); setShowPopover(false)}}>{t('taxondb-attributes:' + props.id)}</Dropdown.Item>
            }
            {props.label &&
                <Dropdown.Item onClick={e => {addDynamicField(props.id); setShowPopover(false)}}>{props.label}</Dropdown.Item>
            }
        </>

    return (
        <Container className={props.path.length > 0 ? "dynamic-logic-panel form-inline" : "dynamic-logic-panel form-inline main-dynamic-logic-panel2"}>
            <div className="panel-content d-flex flex-row">
                <div className="panel-trash">
                    {props.path.length > 0 &&
                        <div className="panel-header d-flex">
                            <div className="ml-auto">
                                <div>
                                    <Confirm
                                        onConfirm={removePanel}
                                        size="md"
                                        confirmationText={t('forms:confirm-remove-dynamic-panel')}
                                        disabled={props.loading}
                                        buttonLabel={t('forms:remove')}
                                    >
                                        <Button variant="light" disabled={props.loading} title={t('forms:remove')}>
                                            <FontAwesomeIcon icon={faTrashAlt} size="lg" />
                                        </Button>
                                    </Confirm>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                {props.path.length > 0 &&
                    <div className="panel-brackets d-flex flex-column justify-content-between">
                        <div className=" d-flex">
                            {(
                                (props.elementId === "and" && (Object.keys(props.thisField).length > 1)) ||
                                props.elementId.startsWith("not_") ||
                                (props.elementId.startsWith("or_") && props.thisField.length > 1)
                            ) &&
                                <div>
                                    {props.elementId.startsWith("not_") &&
                                        <span>{t('taxondb-groups:not')}</span>
                                    }
                                    {props.elementId.startsWith("or_") && props.thisField.length < 2 &&
                                        <span>{t('taxondb-groups:or')}</span>
                                    }
                                    <span> (</span>
                                </div>
                            }
                        </div>
                        <div>
                            {(
                                (props.elementId === "and" && (Object.keys(props.thisField).length > 1)) ||
                                props.elementId.startsWith("not_") ||
                                (props.elementId.startsWith("or_") && props.thisField.length > 1)
                            ) &&
                                <div className="panel-header footer">
                                    <div>)</div>
                                </div>
                            }
                        </div>
                    </div>
                }
                <div className="flex-grow-1 panel-inner">
                    {props.elementId.startsWith("or_") &&
                        <div>
                            {props.thisField && props.thisField.map((val, indx) =>
                                <div key={indx}>
                                    <InnerPanel {...props} path={[ ...props.path, indx ]} elementId="and"></InnerPanel>
                                    {(indx + 1) < props.thisField.length &&
                                        <div>{t('taxondb-groups:or')}</div>
                                    }
                                </div>
                            )}
                        </div>
                    }
                    {!(props.elementId.startsWith("or_")) &&
                        <div>
                            {Object.keys(props.thisField).map((val, indx) =>
                                <div key={indx}>
                                    {(val.startsWith("or_") || val.startsWith("not_")) &&
                                        <InnerPanel {...props} path={[ ...props.path, val ]} elementId={val}></InnerPanel>
                                    }
                                    {(!val.startsWith("or_") && !val.startsWith("not_")) && val !== 'group' &&
                                        <DynamicSearchFormElement {...props} path={[ ...props.path, val ]} elementId={val}></DynamicSearchFormElement>
                                    }
                                    {(indx + 1) < Object.keys(props.thisField).length &&
                                        <div>{t('taxondb-groups:and')}</div>
                                    }
                                </div>
                            )}
                        </div>
                    }
                    <div>
                        <div ref={ref}>
                            <Overlay 
                                target={target.current} 
                                show={showPopover} 
                                container={ref.current} containerPadding={20} 
                                rootClose={true} trigger="click" 
                                flip placement="auto"
                                onHide={e => setShowPopover(false)} >
                                <Popover>
                                    <Popover.Content
                                    >
                                    <NavDropdown title={t('taxondb-groups:group-1')}>
                                        <SingleMenuItem id="kolekcjanumerokazu"></SingleMenuItem>
                                        <SingleMenuItem id={RODZAJ_GATUNEK_ATTRIBUTE_NAME}></SingleMenuItem>
                                        <SingleMenuItem id="jednostkanadrzedna"></SingleMenuItem>
                                        <SingleMenuItem id="autorzbioru"></SingleMenuItem>
                                        <SingleMenuItem id={ROK_ZBIORU_FIELD_NAME}></SingleMenuItem>
                                        <SingleMenuItem id={DATAZBIORU_FIELD_NAME}></SingleMenuItem>
                                        <SingleMenuItem id="autorgatunku"></SingleMenuItem>
                                        <SingleMenuItem id="rangajednostkinadrzednej"></SingleMenuItem>
                                        <SingleMenuItem id="zrodlo"></SingleMenuItem>
                                        <SingleMenuItem id={BOTANIKAZOOLOGIA_FIELD_NAME}></SingleMenuItem>
                                        <SingleMenuItem id={MULTIMEDIA_FIELD_NAME}></SingleMenuItem>
                                    </NavDropdown>
                                    <NavDropdown title={t('taxondb-groups:group-2')}>
                                        <SingleMenuItem id={LOCATION_ATTRIBUTE_NAME}></SingleMenuItem>
                                        <SingleMenuItem id="panstwo"></SingleMenuItem>
                                        <SingleMenuItem id="georeferencjakomentarze"></SingleMenuItem>
                                        <SingleMenuItem id="gmina"></SingleMenuItem>
                                        <SingleMenuItem id="koditypobszarunatura2000"></SingleMenuItem>
                                        <SingleMenuItem id="kontynent"></SingleMenuItem>
                                        <SingleMenuItem id="nazwaobszarunatura2000"></SingleMenuItem>
                                        <SingleMenuItem id="numerzbioruokreslonegoautora"></SingleMenuItem>
                                        <SingleMenuItem id="obszarchronionegokrajobrazu"></SingleMenuItem>
                                        <SingleMenuItem id="obszarchroniony"></SingleMenuItem>
                                        <SingleMenuItem id="parkkrajobrazowy"></SingleMenuItem>
                                        <SingleMenuItem id="parknarodowy"></SingleMenuItem>
                                        <SingleMenuItem id="polozeniewpodzialebiogeograficznymeuropy"></SingleMenuItem>
                                        <SingleMenuItem id="polozeniewpodzialefizjograficznym"></SingleMenuItem>
                                        <SingleMenuItem id="polozeniewpodzialegeograficznympotencjalnejroslinnoscinaturalne"></SingleMenuItem>
                                        <SingleMenuItem id="powiat"></SingleMenuItem>
                                        <SingleMenuItem id="rezerwatprzyrody"></SingleMenuItem>
                                        <SingleMenuItem id="rodzajityprezerwatu"></SingleMenuItem>
                                        <SingleMenuItem id="siedlisko"></SingleMenuItem>
                                        <SingleMenuItem id="uzytekekologiczny"></SingleMenuItem>
                                        <SingleMenuItem id="wojewodztwo"></SingleMenuItem>
                                        <SingleMenuItem id="zespolprzyrodniczokrajobrazowy"></SingleMenuItem>
                                        <SingleMenuItem id={COORDINATES_PRECISION}></SingleMenuItem>
                                    </NavDropdown>
                                    <NavDropdown title={t('taxondb-groups:group-3')}>
                                        <SingleMenuItem id="instytucja"></SingleMenuItem>
                                        <SingleMenuItem id="opisuwagi"></SingleMenuItem>
                                        <SingleMenuItem id="pochodzenieokazu"></SingleMenuItem>
                                    </NavDropdown>
                                    <NavDropdown title={t('taxondb-groups:group-4')}>
                                        <SingleMenuItem id="bibliografia"></SingleMenuItem>
                                    </NavDropdown>
                                    <NavDropdown.Divider></NavDropdown.Divider>
                                    <Dropdown.Item onClick={e => addDynamicField("or")}><b>{t('taxondb-groups:logical-panel-or')}</b></Dropdown.Item>
                                    <Dropdown.Item onClick={e => addDynamicField("not")}><b>{t('taxondb-groups:logical-panel-not')}</b></Dropdown.Item>
                                    </Popover.Content>
                                </Popover>
                            </Overlay>
                            <Button className="dropdown-toggle" ref={target} onClick={e => setShowPopover(!showPopover)} disabled={props.loading}>
                                {t('add')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}

const mapStateToProps = (state, ownProps) => ({
    loading: isPending(ownProps.searchRequestId ? ownProps.searchRequestId : TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, state),
    fields: JSON.parse(getField(ownProps.searchRequestId ? ownProps.searchRequestId : TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, ownProps.searchRequestId ? ownProps.searchRequestId : TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, state, "null")),
    thisField: getElementStructure(ownProps.path,
        JSON.parse(getField(ownProps.searchRequestId ? ownProps.searchRequestId : TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, ownProps.searchRequestId ? ownProps.searchRequestId : TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, state, "null"))),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    setFields: fields => dispatch(setField(ownProps.searchRequestId ? ownProps.searchRequestId : TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, ownProps.searchRequestId ? ownProps.searchRequestId : TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, JSON.stringify(fields))),
    clearResponse: () => {
        if (ownProps.onClear) {
            dispatch(ownProps.onClear())
        } else {
            dispatch(requestRemove(TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID))
            dispatch(requestRemove(TAXONDB_DYNAMIC_SEARCH_SPECIES_REQUEST_ID))
            dispatch(requestRemove(TAXONDB_DYNAMIC_SEARCH_REQUEST_ID))
            dispatch(requestRemoveStartsWith(AGGREGATION_REPORT_REQUEST_ID_PREFIX + TAXONDB_DYNAMIC_SEARCH_REQUEST_ID))
        }
    },
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DynamicSearchFormPanel)
