import React, { useRef, useState } from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { setField } from '../../actions/forms'
import { requestRemove, requestRemoveStartsWith } from '../../actions'
import { isPending, getField } from "../../reducers"
import {
    TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_DYNAMIC2_SEARCH_SPECIES_REQUEST_ID,
    TAXONDB_DYNAMIC2_SEARCH_REQUEST_ID,
    AGGREGATION_REPORT_REQUEST_ID_PREFIX,
} from '../../settings'
import {
    MULTIMEDIA_FIELD_NAME,
    BOTANIKAZOOLOGIA_FIELD_NAME,
    ROK_ZBIORU_FIELD_NAME,
    RODZAJ_GATUNEK_ATTRIBUTE_NAME,
    LOCATION_ATTRIBUTE_NAME,
    COORDINATES_PRECISION,
} from './attributesStructure'
import { Button, Dropdown, NavDropdown, Overlay, Popover } from "react-bootstrap"
import { DATAZBIORU_FIELD_NAME } from './attributesStructure'
import { setElementStructure, getElementStructure, sortNot } from '../../utils/search'
import DynamicSearchFormElement from './DynamicSearchFormElement2'
import InnerPanel from './DynamicSearchFormPanel2'

const DynamicSearchFormPanel = props => {
    const { t } = useTranslation([ 'common', 'taxondb', 'taxondb-attributes', 'forms', 'taxondb-groups' ])

    const [showPopover, setShowPopover] = useState(false)
    const target = useRef()
    const ref = useRef()

    const addDynamicField = (id) => {
        let temp = { ...props.fields }
        const next = Object.keys(props.thisField).reduce((acc, obj) => {
            if (obj.startsWith("or_")) {
                const n = parseInt(obj.substring(3))
                return Math.max(acc, n + 1)
            } else if (obj.startsWith("not_")) {
                const n = parseInt(obj.substring(4))
                return Math.max(acc, n + 1)
            }
            return acc
        }, 0)
        if (Object.keys(props.thisField).filter(i => i === id).length > 0) {
            const output = {}
            Object.keys(temp).forEach(v => {
                if (v !== id) {
                    output[ v ] = temp[ v ]
                } else {
                    output[ "or_" + next ] = [ { [ id ]: temp[ id ] }, { [ id ]: '' } ]
                }
            })
            temp = output
        } else {
            const containingOr = Object.keys(props.thisField).filter(
                i => (
                    i.startsWith("or_") && props.thisField[ i ].find(j => (id in j))
                )
            )
            if (containingOr.length > 0) {
                temp = setElementStructure([ ...props.path, containingOr[ 0 ] ], temp, [ ...temp[ containingOr[ 0 ] ], { [ id ]: '' } ])
            } else {
                if (Object.keys(temp).filter(v => (v.startsWith('not_') && id in temp[ v ])).length) {
                    const output = {}
                    let keyFound = false
                    Object.keys(temp).forEach(v => {
                        if (v.startsWith("not_") && (id in props.thisField[ v ])) {
                            keyFound = true
                        } else {
                            if (keyFound) {
                                keyFound = false
                                output[ id ] = ""
                            }
                        }
                        output[ v ] = temp[ v ]
                    })
                    if (keyFound) {
                        keyFound = false
                        output[ id ] = ""
                    }
                    temp = output
                } else {
                    temp = setElementStructure([ ...props.path, id ], temp, "")
                }
            }
        }
        props.setFields(temp)
        props.clearResponse()
    }

    const SingleMenuItem = props =>
        <>
            {!props.label &&
                <Dropdown.Item onClick={e => {addDynamicField(props.id); setShowPopover(false)}}>{t('taxondb-attributes:' + props.id)}</Dropdown.Item>
            }
            {props.label &&
                <Dropdown.Item onClick={e => {addDynamicField(props.id); setShowPopover(false)}}>{props.label}</Dropdown.Item>
            }
        </>

    return (
        <div className="form-inline">
            <div>
                {props.elementId.startsWith("or_") &&
                    <div>
                        {props.thisField && props.thisField.map((val, indx) =>
                            <div key={indx} className="d-flex justify-content-end">
                                <InnerPanel {...props} path={[ ...props.path, indx ]} elementId="and"></InnerPanel>
                            </div>
                        )}
                    </div>
                }
                {!(props.elementId.startsWith("or_")) &&
                    <div>
                        {Object.keys(props.thisField).map((val, indx) =>
                            <div key={indx} className="d-flex justify-content-end">
                                {val.startsWith("or_") &&
                                    <InnerPanel {...props} path={[ ...props.path, val ]} elementId={val}></InnerPanel>
                                }
                                {val.startsWith("not_") &&
                                    <InnerPanel {...props}
                                        path={[ ...props.path, val ]}
                                        elementId={val}
                                        previous={indx > 0 &&
                                            Object.keys(props.thisField).filter(
                                                (o, i) => (
                                                    i < indx &&
                                                    o.startsWith('not_') &&
                                                    Object.keys(props.thisField[ o ])[ 0 ] === Object.keys(props.thisField[ val ])[ 0 ]
                                                )).length
                                        }></InnerPanel>
                                }
                                {(!val.startsWith("or_") && !val.startsWith("not_")) && val !== 'group' &&
                                    <DynamicSearchFormElement {...props} path={[ ...props.path, val ]} elementId={val} previous={props.previous}></DynamicSearchFormElement>
                                }
                            </div>
                        )}
                    </div>
                }
                {(props.path.length === 0 || (props.elementId.startsWith('not_') && Object.keys(props.thisField).length === 0)) &&
                    <div className="mt-2">
                        <div ref={ref}>
                            <Overlay 
                                target={target.current} 
                                show={showPopover} 
                                container={ref.current} containerPadding={20} 
                                rootClose={true} trigger="click" 
                                flip placement="auto"
                                onHide={e => setShowPopover(false)} >
                            <Popover>
                            <Popover.Content>
                            <NavDropdown title={t('taxondb-groups:group-1')}>
                                <SingleMenuItem id="kolekcjanumerokazu"></SingleMenuItem>
                                <SingleMenuItem id={RODZAJ_GATUNEK_ATTRIBUTE_NAME}></SingleMenuItem>
                                <SingleMenuItem id="jednostkanadrzedna"></SingleMenuItem>
                                <SingleMenuItem id="autorzbioru"></SingleMenuItem>
                                <SingleMenuItem id={ROK_ZBIORU_FIELD_NAME}></SingleMenuItem>
                                <SingleMenuItem id={DATAZBIORU_FIELD_NAME}></SingleMenuItem>
                                <SingleMenuItem id="autorgatunku"></SingleMenuItem>
                                <SingleMenuItem id="rangajednostkinadrzednej"></SingleMenuItem>
                                <SingleMenuItem id="zrodlo"></SingleMenuItem>
                                <SingleMenuItem id={BOTANIKAZOOLOGIA_FIELD_NAME}></SingleMenuItem>
                                <SingleMenuItem id={MULTIMEDIA_FIELD_NAME}></SingleMenuItem>
                            </NavDropdown>
                            <NavDropdown title={t('taxondb-groups:group-2')}>
                                <SingleMenuItem id={LOCATION_ATTRIBUTE_NAME}></SingleMenuItem>
                                <SingleMenuItem id="panstwo"></SingleMenuItem>
                                <SingleMenuItem id="georeferencjakomentarze"></SingleMenuItem>
                                <SingleMenuItem id="gmina"></SingleMenuItem>
                                <SingleMenuItem id="koditypobszarunatura2000"></SingleMenuItem>
                                <SingleMenuItem id="kontynent"></SingleMenuItem>
                                <SingleMenuItem id="nazwaobszarunatura2000"></SingleMenuItem>
                                <SingleMenuItem id="numerzbioruokreslonegoautora"></SingleMenuItem>
                                <SingleMenuItem id="obszarchronionegokrajobrazu"></SingleMenuItem>
                                <SingleMenuItem id="obszarchroniony"></SingleMenuItem>
                                <SingleMenuItem id="parkkrajobrazowy"></SingleMenuItem>
                                <SingleMenuItem id="parknarodowy"></SingleMenuItem>
                                <SingleMenuItem id="polozeniewpodzialebiogeograficznymeuropy"></SingleMenuItem>
                                <SingleMenuItem id="polozeniewpodzialefizjograficznym"></SingleMenuItem>
                                <SingleMenuItem id="polozeniewpodzialegeograficznympotencjalnejroslinnoscinaturalne"></SingleMenuItem>
                                <SingleMenuItem id="powiat"></SingleMenuItem>
                                <SingleMenuItem id="rezerwatprzyrody"></SingleMenuItem>
                                <SingleMenuItem id="rodzajityprezerwatu"></SingleMenuItem>
                                <SingleMenuItem id="siedlisko"></SingleMenuItem>
                                <SingleMenuItem id="uzytekekologiczny"></SingleMenuItem>
                                <SingleMenuItem id="wojewodztwo"></SingleMenuItem>
                                <SingleMenuItem id="zespolprzyrodniczokrajobrazowy"></SingleMenuItem>
                                <SingleMenuItem id={COORDINATES_PRECISION}></SingleMenuItem>
                            </NavDropdown>
                            <NavDropdown title={t('taxondb-groups:group-3')}>
                                <SingleMenuItem id="instytucja"></SingleMenuItem>
                                <SingleMenuItem id="opisuwagi"></SingleMenuItem>
                                <SingleMenuItem id="pochodzenieokazu"></SingleMenuItem>
                            </NavDropdown>
                            <NavDropdown title={t('taxondb-groups:group-4')}>
                                <SingleMenuItem id="bibliografia"></SingleMenuItem>
                            </NavDropdown>
                        </Popover.Content>
                        </Popover>
                        </Overlay>
                        <Button className="dropdown-toggle" ref={target} onClick={e => setShowPopover(!showPopover)} disabled={props.loading}>
                            {t('add')}
                        </Button>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    loading: isPending(ownProps.searchRequestId ? ownProps.searchRequestId : TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, state),
    fields: JSON.parse(getField(ownProps.searchRequestId ? ownProps.searchRequestId : TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, ownProps.searchRequestId ? ownProps.searchRequestId : TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, state, "null")),
    thisField: getElementStructure(ownProps.path,
        JSON.parse(getField(ownProps.searchRequestId ? ownProps.searchRequestId : TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, ownProps.searchRequestId ? ownProps.searchRequestId : TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, state, "null"))),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    setFields: fields => dispatch(setField(ownProps.searchRequestId ? ownProps.searchRequestId : TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, ownProps.searchRequestId ? ownProps.searchRequestId : TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, JSON.stringify(sortNot(fields)))),
    clearResponse: () => {
        if (ownProps.onClear) {
            dispatch(ownProps.onClear())
        } else {
            dispatch(requestRemove(TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID))
            dispatch(requestRemove(TAXONDB_DYNAMIC2_SEARCH_SPECIES_REQUEST_ID))
            dispatch(requestRemove(TAXONDB_DYNAMIC2_SEARCH_REQUEST_ID))
            dispatch(requestRemoveStartsWith(AGGREGATION_REPORT_REQUEST_ID_PREFIX + TAXONDB_DYNAMIC2_SEARCH_REQUEST_ID))
        }
    },
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DynamicSearchFormPanel)
