import React, { useState } from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Spinner, Button, ListGroup, Collapse, Form } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortUp, faSortDown, faChartBar, faChartPie, faPencilAlt, faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons'
import { Bar, Pie, Chart } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import * as htmlToImage from 'html-to-image';
import { Redirect } from "react-router-dom"

import { postDataAnc } from '../../actions'
import {
  ANC_URL_POSTFIX,
  TAXONDB_SEARCH_WITH_AGGREGATION_REQUEST_URL,
  AGGREGATION_REPORT_REQUEST_ID_PREFIX,
  TAXONDB_SEARCH_REQUEST_URL,
  COMMON_DICTIONARIES,
  DICTIONARY_COLLECTION_TYPES,
  TAXONDB_EXPORTFILE_WITH_AGGREGATION_REQUEST_URL,
  USER_GROUPS_EDITOR_CONST,
  TAXONDB_CHANGE_GROUP_REQUEST_URL,
  TAXONDB_CHANGE_GROUP_REQUEST_ID,
  TAXON_FIELDS_ALLOWED_IN_GROUP_CHANGE,
  ARRANGEMENT_PAGE_URL_EN,
  ARRANGEMENT_PAGE_URL_PL,
  ARRANGEMENT_TOOLS_ARRANGEMENT_PANEL,
  ARRANGEMENT_TOOLS_SEARCH_PANEL,
  ARRANGEMENT_TOOLS_ACTIVE_VISUALIZATION,
  ARRANGEMENT_BY_POINTS,
  ARRANGEMENT_TOOLS_SEARCH_ON_INIT,
  TAXONDB_SEARCH_GENUS_REQUEST_ID,
  TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID,
  TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID,
  TAXON_SEARCH_IS_DYNAMIC,
  TAXONDB_DYNAMIC_SEARCH_REQUEST_ID,
  TAXONDB_DYNAMIC2_SEARCH_REQUEST_ID
} from '../../settings'
import { getItems, getPagination, isPending, getField, getTotal } from "../../reducers";
import ConnectedPagination from "../ConnectedPagination"
import ValueFromDictionary from "../dictionary/ValueFromDictionary"
import { setField, setFields } from "../../actions/forms";
import InnerReportList from "./ReportList"
import ResultsList from '../taxonDB/ResultsList'
import { GetValueFromDictionary } from "../dictionary/ValueFromDictionary"
import ReportsExporter from "../ReportsExporter"
import { RODZAJ_GATUNEK_ATTRIBUTE_NAME } from '../taxonDB/attributesStructure';
import * as notify from '../../utils/notify'
import InProgress from './InProgress'
import ListGroupItemDiv from "../ListGroupItemDiv";

const ReportList = props => {
  const { t, i18n } = useTranslation([ 'taxondb-report', 'taxondb-attributes', 'forms' ])

  const [ open, setOpen ] = useState({})
  const [ isOpened, setIsOpened ] = useState({})
  const [ sortInfo, setSortInfo ] = useState({})
  const [ isBarChart, setIsBarChart ] = useState(false)
  const [ isPieChart, setIsPieChart ] = useState(false)
  const [ newValue, setNewValue ] = useState('')
  const [ oldValue, setOldValue ] = useState('')
  const [ editingItem, setEditingItem ] = useState(null)
  const [ inProgress, setInProgress ] = useState({})
  const [ redirectToMap, setRedirectToMap ] = useState(false)

  Chart.plugins.unregister([ ChartDataLabels ])

  const toggleOpen = (i, val) => {
    const indx = 'is-open-' + props.pagination.perPage + '-' + props.pagination.currentPage + '-' + i
    const temp = { ...open }
    if (temp[ indx ]) {
      temp[ indx ] = false
    } else {
      temp[ indx ] = true
      const obj = {
        filter: { ...props.filter }
      }
      // Warning! filter.group is a reference!
      // need to create a new object to temporary modify!
      const group = { ...props.filter.group }
      group[ props.formPanels[ props.indx ].aggregation_field ] = val[ props.formPanels[ props.indx ].aggregation_field ]
      obj.filter.group = group
      if (props.indx === props.formPanels.length - 1) {
        // results
        props.search(obj, i, props.pagination)
      } else {
        // next level aggregation
        obj.aggregation_field = props.formPanels[ props.indx + 1 ].aggregation_field
        obj.skip_null = props.formPanels[ props.indx + 1 ].skip_null
        obj.with_total = true
        if (props.formPanels[ props.indx + 1 ].limit > 0) {
          obj.pagination = { perPage: parseInt(props.formPanels[ props.indx + 1 ].limit) }
        }
        props.searchWithAggregation(obj, i, props.pagination)
      }
    }

    setOpen(temp)
  }

  const setSort = obj => {
    const data = { ...props.formPanels[ props.indx ], filter: props.filter, ...obj }
    props.searchWithSort(data)
    setSortInfo(obj)
    setOpen({})
    setIsPieChart(false)
    setIsBarChart(false)
  }

  const colors = [ '#a6cee3', '#1f78b4', '#b2df8a', '#33a02c', '#fb9a99', '#e31a1c', '#fdbf6f', '#ff7f00', '#cab2d6', '#6a3d9a', '#ffff99', '#b15928' ]

  const maxItemsInPie = 12

  let data = {}
  let dataPie = {}

  if (props.items) {
    data = {
      labels: props.items.map(val => GetValueFromDictionary(props.formPanels[ props.indx ].aggregation_field, val[ props.formPanels[ props.indx ].aggregation_field ], i18n, props.collectionTypes)),
      datasets: [ {
        data: props.items.map(val => val.liczba_okazow),
        label: t('taxondb-attributes:' + props.formPanels[ props.indx ].aggregation_field) + ' / ' + t('taxondb-report:nr_of_specimens_capital'),
        backgroundColor: '#0056B3',
      } ]
    }

    dataPie = {
      labels: props.items.map(val => GetValueFromDictionary(props.formPanels[ props.indx ].aggregation_field, val[ props.formPanels[ props.indx ].aggregation_field ], i18n, props.collectionTypes)).filter((obj, indx) => indx < maxItemsInPie),
      datasets: [ {
        data: props.items.map(val => val.liczba_okazow).filter((obj, indx) => indx < maxItemsInPie),
        label: t('taxondb-attributes:' + props.formPanels[ props.indx ].aggregation_field) + ' / ' + t('taxondb-report:nr_of_specimens_capital'),
        backgroundColor: props.items.map((val, indx) => colors[ indx ]).filter((obj, indx) => indx < maxItemsInPie),
      } ]
    }

    if (props.formPanels[ props.indx ].limit > 0 && (props.totalCount - props.items.reduce((curr, obj) => curr + obj.liczba_okazow, 0)) > 0) {
      data.labels.push(t('taxondb-report:others'))
      data.datasets[ 0 ].data.push(props.totalCount - props.items.reduce((curr, obj) => curr + obj.liczba_okazow, 0))
    }
    if (props.items.length > maxItemsInPie ||
      (props.formPanels[ props.indx ].limit > 0 && (props.totalCount - props.items.reduce((curr, obj) => curr + obj.liczba_okazow, 0)) > 0)) {
      const other = props.totalCount - props.items.filter((obj, indx) => indx < maxItemsInPie).reduce((curr, obj) => curr + obj.liczba_okazow, 0)
      dataPie.labels.push(t('taxondb-report:others'))
      dataPie.datasets[ 0 ].data.push(other)
      dataPie.datasets[ 0 ].backgroundColor.push('#555555')
    }
  }

  const options = {
  }

  const download = type => {
    htmlToImage.toPng(document.getElementById(type + '-ref-' + props.indx + '-' + props.path),
      { backgroundColor: "#ffffff", filter: false })
      .then(function (dataUrl) {
        var element = document.createElement('a');
        element.setAttribute('href', dataUrl);
        element.setAttribute('download', type + '-chart.png');
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      });
  }

  const UglyHack = props => <>
    {props.formPanels[ props.indx ].aggregation_field === RODZAJ_GATUNEK_ATTRIBUTE_NAME &&
      <div>
        {props.val.endsWith('sp.') &&
          <>
            <i>{props.val.split('sp.')[ 0 ]}</i> sp.
          </>
        }
        {!props.val.endsWith('sp.') &&
          <>
            <i>{props.val}</i>
          </>
        }
      </div>
    }
    {props.formPanels[ props.indx ].aggregation_field !== RODZAJ_GATUNEK_ATTRIBUTE_NAME &&
      <ValueFromDictionary
        name={props.formPanels[ props.indx ].aggregation_field}
        value={props.val}
        {...props}
      ></ValueFromDictionary>
    }
  </>

  const editValue = (event, indx, val) => {
    event.preventDefault();
    event.stopPropagation();
    setOldValue(val)
    setNewValue(val);
    setEditingItem(indx);
  }

  const goToMap = (event, val) => {
    event.preventDefault();
    event.stopPropagation();
    props.setShowArrangementPanel(false)
    props.setShowSearchPanel(false)
    props.setActiveVisualization(ARRANGEMENT_BY_POINTS)
    const filter = { ...props.filter }
    const group = { ...props.filter.group }
    group[ props.formPanels[ props.indx ].aggregation_field ] = val
    filter.group = group
    switch (props.requestId) {
      case TAXONDB_DYNAMIC2_SEARCH_REQUEST_ID:
        props.setIsDynamic("2")
        props.setField(TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, filter)
        break;
      case TAXONDB_DYNAMIC_SEARCH_REQUEST_ID:
        props.setIsDynamic("1")
        props.setField(TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, filter)
        break;
      default:
        props.setIsDynamic("0")
        props.setFields(TAXONDB_SEARCH_GENUS_REQUEST_ID, filter)
    }
    props.setSearchOnInit(true)
    setRedirectToMap(true)
  }

  const saveValue = event => {
    event.preventDefault();
    event.stopPropagation();
    props.changeGroup({
      filter: props.filter,
      field: props.formPanels[ props.indx ].aggregation_field === 'siedlisko' ? 'siedlisko1' : props.formPanels[ props.indx ].aggregation_field,
      old_value: oldValue,
      new_value: newValue,
    }).then(res => {
      const temp = { ...inProgress }
      temp[ editingItem ] = res.task_id
      setInProgress(temp)
      notify.success(t('taxondb-report:task-submitted', { task_id: res.task_id }))
    }).catch(e => {
      notify.error(t('taxondb-report:task-submission-failed'))
    }).finally(() => {
      setEditingItem(null);
    })
  }

  const cancelValue = event => {
    event.preventDefault();
    event.stopPropagation();
    setEditingItem(null);
  }

  return (
    <>
      {redirectToMap &&
        <Redirect push to={(i18n.language.startsWith('pl') ? ARRANGEMENT_PAGE_URL_PL : ARRANGEMENT_PAGE_URL_EN) + '/'}></Redirect>
      }
      {props.loading &&
        <div className="align-self-center p-5">
          <Spinner animation="border" role="status" />
        </div>
      }
      {!props.loading &&
        <div>
          {props.items && props.totalCount > 0 &&
            <div className="d-flex flex-lg-row-reverse flex-column">
              {(isBarChart || isPieChart) &&
                <div className="w-100 mt-3">
                  <div>
                    {isBarChart &&
                      <div>
                        <div id={'bar-ref-' + props.indx + '-' + props.path}>
                          <Bar data={canvas => {
                            canvas.setAttribute('aria-label', t('bar-chart-a11y'));
                            canvas.setAttribute('role', 'img');
                            return data
                            }} options={options} />
                        </div>
                        <Button className="mb-3" onClick={e => download('bar')}>{t('save-chart-to-file')}</Button>
                      </div>
                    }
                  </div>
                  <div>
                    {isPieChart &&
                      <div>
                        <div id={'pie-ref-' + props.indx + '-' + props.path}>
                          <Pie data={canvas => {
                            canvas.setAttribute('aria-label', t('pie-chart-a11y'));
                            canvas.setAttribute('role', 'img');
                            return dataPie
                            }} options={options} />
                        </div>
                        <Button className="mb-3" onClick={e => download('pie')}>{t('save-chart-to-file')}</Button>
                      </div>
                    }
                  </div>
                </div>
              }
              <div className="d-flex flex-column mt-2 mb-2 w-100">
                <ReportsExporter
                  requestId={AGGREGATION_REPORT_REQUEST_ID_PREFIX + props.requestId + '-search-' + props.indx + '-' + props.path}
                  url={TAXONDB_EXPORTFILE_WITH_AGGREGATION_REQUEST_URL}
                  customFilter={{ ...props.formPanels[ props.indx ], filter: props.filter, ...sortInfo }}
                >
                </ReportsExporter>
                <div className="d-flex align-items-end">
                  <div>
                    <Button size="sm" variant="light" active={isBarChart} onClick={() => setIsBarChart(!isBarChart)}>
                      <FontAwesomeIcon icon={faChartBar} size="lg" title={t('taxondb-report:bar-chart-icon')} />
                    </Button>
                  </div>
                  <div>
                    <Button size="sm" variant="light" active={isPieChart} onClick={() => setIsPieChart(!isPieChart)}>
                      <FontAwesomeIcon icon={faChartPie} size="lg" title={t('taxondb-report:pie-chart-icon')} />
                    </Button>
                  </div>
                  <div className="flex-grow-1">
                    {!props.formPanels[ props.indx ].limit &&
                      <ConnectedPagination
                        requestId={AGGREGATION_REPORT_REQUEST_ID_PREFIX + props.requestId + '-search-' + props.indx + '-' + props.path}
                        url={ANC_URL_POSTFIX + TAXONDB_SEARCH_WITH_AGGREGATION_REQUEST_URL}
                        customFilter={{ ...props.formPanels[ props.indx ], filter: props.filter, ...sortInfo }}
                      ></ConnectedPagination>
                    }
                  </div>
                </div>

                <ListGroup.Item tabIndex="0" action className="d-flex align-items-center" as="div">
                  <div className="flex-grow-1">
                    <Button variant="link" className="pl-0 pr-1" onClick={e => setSort({
                      sort_by_count: false,
                      sort_desc: sortInfo.sort_desc === false ? true : false,
                    })}>
                      <b>{t('taxondb-attributes:' + props.formPanels[ props.indx ].aggregation_field)}</b>
                    </Button>
                    {sortInfo.sort_desc === false && sortInfo.sort_by_count === false &&
                      <FontAwesomeIcon icon={faSortUp} />
                    }
                    {sortInfo.sort_desc !== false && sortInfo.sort_by_count === false &&
                      <FontAwesomeIcon icon={faSortDown} />
                    }
                  </div>
                  <div style={{ textAlign: "right" }} className="mr-2 w-25">
                    <Button style={{ textAlign: "right" }} variant="link" className="pl-0 pr-1" onClick={e => setSort({
                      sort_by_count: true,
                      sort_desc: sortInfo.sort_desc === false ? true : false,
                    })}><b>{t('taxondb-report:nr_of_specimens_capital')}</b></Button>
                    {sortInfo.sort_desc === false && sortInfo.sort_by_count !== false &&
                      <FontAwesomeIcon icon={faSortUp} />
                    }
                    {sortInfo.sort_desc !== false && sortInfo.sort_by_count !== false &&
                      <FontAwesomeIcon icon={faSortDown} />
                    }
                  </div>
                  <div style={{ textAlign: "right" }} className="w-25"><b>{t('taxondb-report:percentage_of_results')}</b></div>
                </ListGroup.Item>
                {props.items.map((val, indx) =>
                  <React.Fragment key={indx}>
                    <ListGroupItemDiv className="d-flex album-element" onClick={e => toggleOpen(indx, val)}>
                      {(editingItem === null || editingItem !== indx) &&
                        <div className="d-flex flex-grow-1 justify-content-between align-items-center">
                          {open[ 'is-open-' + props.pagination.perPage + '-' + props.pagination.currentPage + '-' + indx ] &&
                            <img src="/images/list-open.svg" alt={t("a11y:list-opened")} />
                          }
                          {!open[ 'is-open-' + props.pagination.perPage + '-' + props.pagination.currentPage + '-' + indx ] &&
                            <img src="/images/list-closed.svg" alt={t("a11y:list-closed")} />
                          }
                          <UglyHack
                            {...props}
                            val={val[ props.formPanels[ props.indx ].aggregation_field ]}
                          ></UglyHack>
                          {inProgress[ indx ] &&
                            <InProgress
                              formPanels={props.formPanels}
                              indx={props.indx}
                              editingItem={indx}
                              newValue={newValue}
                              max={val.liczba_okazow}
                              task_id={inProgress[ indx ]}
                              inProgress={inProgress}
                              onDone={setInProgress}
                              requestId={props.requestId}
                              path={props.path}></InProgress>
                          }
                          <div>
                            {props.user != null && props.user.groups != null &&
                              props.user.groups.includes(USER_GROUPS_EDITOR_CONST) &&
                              TAXON_FIELDS_ALLOWED_IN_GROUP_CHANGE.includes(props.formPanels[ props.indx ].aggregation_field) &&
                                <Button
                                  onClick={e => editValue(e, indx, val[ props.formPanels[ props.indx ].aggregation_field ])}
                                  size="sm"
                                  variant="secondary"
                                  disabled={editingItem !== null || inProgress[ indx ]}
                                  className="mr-1"
                                >
                                  <FontAwesomeIcon icon={faPencilAlt} title={t('forms:edit')} />
                                </Button>
                            }
                            <Button
                              onClick={e => goToMap(e, val[ props.formPanels[ props.indx ].aggregation_field ])}
                              size="sm"
                              variant="secondary"
                              disabled={editingItem !== null || inProgress[ indx ]}
                            >
                              <FontAwesomeIcon icon={faMapMarkedAlt} title={t('taxondb-report:map')} />
                            </Button>
                          </div>
                        </div>
                      }
                      {editingItem === indx &&
                        <div className="d-flex flex-grow-1 justify-content-between align-items-center">
                          <Form.Control
                            type="text"
                            value={newValue}
                            onChange={e => setNewValue(e.target.value)}
                            autoFocus
                            autoComplete="off"
                            className="mr-1"
                            disabled={props.editing}
                          ></Form.Control>
                          <div className="d-flex">
                            <Button
                              onClick={saveValue}
                              size="sm"
                              variant="success"
                              className="mr-1"
                              disabled={oldValue === newValue || props.editing}
                            >
                              {t('forms:save')}
                            </Button>
                            <Button
                              onClick={cancelValue}
                              size="sm"
                              variant="secondary"
                              disabled={props.editing}
                            >{t('forms:cancel')}</Button>
                            {props.editing &&
                              <Spinner
                                as="span"
                                animation="border"
                                size="md"
                                role="status"
                                aria-hidden="true"
                                className="ml-1"
                              />
                            }
                          </div>
                        </div>
                      }
                      <div style={{ textAlign: "right" }} className="mr-3 w-25">{val.liczba_okazow.toLocaleString(i18n.languages[0])}</div>
                      <div style={{ textAlign: "right" }} className="w-25">{(val.liczba_okazow * 100.0 / props.totalCount).toLocaleString(i18n.languages[0], { maximumFractionDigits: 2 })} %</div>
                    </ListGroupItemDiv>
                    {props.indx < (props.formPanels.length - 1) &&
                      <Collapse in={open[ 'is-open-' + props.pagination.perPage + '-' + props.pagination.currentPage + '-' + indx ]}
                        id={'is-open-' + props.pagination.perPage + '-' + props.pagination.currentPage + '-' + indx}
                        onEntering={() => setIsOpened({ ...isOpened, [ 'is-open-' + props.pagination.perPage + '-' + props.pagination.currentPage + '-' + indx ]: true })}
                        onExited={() => setIsOpened({ ...isOpened, [ 'is-open-' + props.pagination.perPage + '-' + props.pagination.currentPage + '-' + indx ]: false })}
                      >
                        <div className="ml-4">
                          {(open[ 'is-open-' + props.pagination.perPage + '-' + props.pagination.currentPage + '-' + indx ] === true || isOpened[ 'is-open-' + props.pagination.perPage + '-' + props.pagination.currentPage + '-' + indx ]) &&
                            <InnerReportList
                              indx={props.indx + 1}
                              requestId={props.requestId}
                              path={props.pagination.perPage + '-' + props.pagination.currentPage + '-' + indx}
                            ></InnerReportList>
                          }
                        </div>
                      </Collapse>
                    }
                    {props.indx === (props.formPanels.length - 1) &&
                      <Collapse in={open[ 'is-open-' + props.pagination.perPage + '-' + props.pagination.currentPage + '-' + indx ]}
                        id={'is-open-' + props.pagination.perPage + '-' + props.pagination.currentPage + '-' + indx}
                        onEntering={() => setIsOpened({ ...isOpened, [ 'is-open-' + props.pagination.perPage + '-' + props.pagination.currentPage + '-' + indx ]: true })}
                        onExited={() => setIsOpened({ ...isOpened, [ 'is-open-' + props.pagination.perPage + '-' + props.pagination.currentPage + '-' + indx ]: false })}
                      >
                        <div className="ml-4">
                          {(open[ 'is-open-' + props.pagination.perPage + '-' + props.pagination.currentPage + '-' + indx ] === true || isOpened[ 'is-open-' + props.pagination.perPage + '-' + props.pagination.currentPage + '-' + indx ]) &&
                            <ResultsList
                              requestId={AGGREGATION_REPORT_REQUEST_ID_PREFIX + props.requestId +
                                '-results-' + (props.indx + 1) + '-' + props.pagination.perPage + '-' + props.pagination.currentPage + '-' + indx}></ResultsList>
                          }
                        </div>
                      </Collapse>
                    }
                  </React.Fragment>
                )}
                {props.formPanels[ props.indx ].limit > 0 && (props.totalCount - props.items.reduce((curr, obj) => curr + obj.liczba_okazow, 0)) > 0 &&
                  <ListGroup.Item tabIndex="0" action className="d-flex">
                    <div className="flex-grow-1">{t('taxondb-report:others')}</div>
                    <div style={{ textAlign: "right" }} className="mr-3 w-25">{(props.totalCount - props.items.reduce((curr, obj) => curr + obj.liczba_okazow, 0)).toLocaleString(i18n.languages[0])}</div>
              <div style={{ textAlign: "right" }} className="w-25">{((props.totalCount - props.items.reduce((curr, obj) => curr + obj.liczba_okazow, 0)) * 100.0 / props.totalCount).toLocaleString(i18n.languages[0], { maximumFractionDigits: 2 })} %</div>
                  </ListGroup.Item>
                }

                {!props.formPanels[ props.indx ].limit &&
                  <ConnectedPagination
                    last
                    requestId={AGGREGATION_REPORT_REQUEST_ID_PREFIX + props.requestId + '-search-' + props.indx + '-' + props.path}
                    url={ANC_URL_POSTFIX + TAXONDB_SEARCH_WITH_AGGREGATION_REQUEST_URL}
                    customFilter={{ ...props.formPanels[ props.indx ], filter: props.filter, ...sortInfo }}
                  ></ConnectedPagination>
                }
              </div>
            </div>
          }
          {props.items && !props.totalCount &&
            <p>{t('common:no-records-found')}</p>
          }
        </div>
      }
    </>
  )
}

const mapStateToProps = (state, ownProps) => ({
  loading: isPending(AGGREGATION_REPORT_REQUEST_ID_PREFIX + ownProps.requestId + '-search-' + ownProps.indx + '-' + ownProps.path, state),
  filter: getField(AGGREGATION_REPORT_REQUEST_ID_PREFIX + ownProps.requestId + '-search-' + ownProps.indx + '-' + ownProps.path, 'aggregation-filter', state),
  pagination: getPagination(AGGREGATION_REPORT_REQUEST_ID_PREFIX + ownProps.requestId + '-search-' + ownProps.indx + '-' + ownProps.path, state),
  items: getItems(AGGREGATION_REPORT_REQUEST_ID_PREFIX + ownProps.requestId + '-search-' + ownProps.indx + '-' + ownProps.path, state),
  formPanels: getField(
    AGGREGATION_REPORT_REQUEST_ID_PREFIX + ownProps.requestId + '-form',
    'panels', state, [ {
      aggregation_field: 'rodzaj',
      limit: "",
      skip_null: false,
      with_total: true,
      filter: getField(AGGREGATION_REPORT_REQUEST_ID_PREFIX + ownProps.requestId + '-search-' + ownProps.indx + '-' + ownProps.path, 'aggregation-filter', state),
    } ]),
  collectionTypes: getField(COMMON_DICTIONARIES, DICTIONARY_COLLECTION_TYPES, state, []),
  user: state.user ? state.user.data : null,
  editing: isPending(TAXONDB_CHANGE_GROUP_REQUEST_ID, state),
  totalCount: getTotal(AGGREGATION_REPORT_REQUEST_ID_PREFIX + ownProps.requestId + '-search-' + ownProps.indx + '-' + ownProps.path, state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  searchWithAggregation: (obj, i, pagination) => {
    const requestId = AGGREGATION_REPORT_REQUEST_ID_PREFIX + ownProps.requestId +
      '-search-' + (ownProps.indx + 1) + '-' + pagination.perPage + '-' + pagination.currentPage + '-' + i;
    dispatch(setField(requestId, 'aggregation-filter', obj.filter));
    return dispatch(postDataAnc(requestId, TAXONDB_SEARCH_WITH_AGGREGATION_REQUEST_URL, obj));
  },
  search: (obj, i, pagination) => dispatch(postDataAnc(AGGREGATION_REPORT_REQUEST_ID_PREFIX + ownProps.requestId +
    '-results-' + (ownProps.indx + 1) + '-' + pagination.perPage + '-' + pagination.currentPage + '-' + i,
    TAXONDB_SEARCH_REQUEST_URL, obj)),
  searchWithSort: obj => {
    const data = { ...obj }
    if (data.limit > 0) {
      data.pagination = { perPage: parseInt(obj.limit) }
    }
    delete data.limit
    return dispatch(postDataAnc(AGGREGATION_REPORT_REQUEST_ID_PREFIX + ownProps.requestId + '-search-' + ownProps.indx + '-' + ownProps.path,
      TAXONDB_SEARCH_WITH_AGGREGATION_REQUEST_URL, data))
  },
  changeGroup: data => dispatch(postDataAnc(TAXONDB_CHANGE_GROUP_REQUEST_ID, TAXONDB_CHANGE_GROUP_REQUEST_URL, data)),
  setShowArrangementPanel: value => dispatch(setField(ARRANGEMENT_TOOLS_ARRANGEMENT_PANEL, ARRANGEMENT_TOOLS_ARRANGEMENT_PANEL, value)),
  setShowSearchPanel: value => dispatch(setField(ARRANGEMENT_TOOLS_SEARCH_PANEL, ARRANGEMENT_TOOLS_SEARCH_PANEL, value)),
  setActiveVisualization: value => dispatch(setField(ARRANGEMENT_TOOLS_ACTIVE_VISUALIZATION, ARRANGEMENT_TOOLS_ACTIVE_VISUALIZATION, value)),
  setIsDynamic: value => dispatch(setField(TAXON_SEARCH_IS_DYNAMIC, TAXON_SEARCH_IS_DYNAMIC, value)),
  setFields: (requestId, value) => dispatch(setFields(requestId, value)),
  setField: (requestId, value) => dispatch(setField(requestId, requestId, JSON.stringify(value))),
  setSearchOnInit: value => dispatch(setField(ARRANGEMENT_TOOLS_SEARCH_ON_INIT, ARRANGEMENT_TOOLS_SEARCH_ON_INIT, value)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportList)
